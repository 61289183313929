.hero {
  height: 25vh;
  text-align: center;
  background-image: url("./assets/images/hero_mobile.png");
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 1024px) {
  .hero {
    height: 326px;
    background: none;
  }
}

.hero__img {
  display: none;
}

@media (min-width: 1024px) {
  .hero__img {
    display: block;
  }
}

.hero__txt {
  position: absolute;
  top: 60%;
  width: 100%;
}

.hero__overlay {
  top: 0;
  gap: 20px;
  height: 100%;
  background-color: rgba(57, 57, 57, 0.4);
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .hero__overlay {
    top: -100%;
    height: 326px;
    gap: 50px;
  }
}

.hero__overlay h1 {
  margin:0;
}

.project__container {
  margin-top: 35px;
  margin-bottom: 35px;
  text-align: center;
  justify-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1024px) {
  .project__container {
    display: grid;
    /* margin-left: 60px; */
    grid-template-columns: repeat(2, 1fr);
    margin-left: 150px;
    margin-right: 150px;
    column-gap: 10px;
    row-gap: 5px;
    grid-auto-rows: minmax(100px, auto);
    justify-content: center;
  }
}

.project {
  width: 80vw;
  height: 80vw;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
}

@media (min-width: 1024px) {
  .project {
    display: grid;
    height: 350px;
    width: 350px;
  }
}

.sapien {
  background-image: url("./assets/images/sapien.svg");
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
}

.tot {
  margin-top: 100px;
  background-image: url("./assets/images/tot.png");
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
}

.rnn {
  margin-top: 100px;
  background-image: url("./assets/images/rnn.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
}

.ailab {
  background-image: url("./assets/images/ailab.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
}

.credifai {
  margin-top: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
}